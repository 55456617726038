"use strict";

$(document).ready(function() {
  var main = $(".site-main");
  var mobileMenuTrigger = $("#js-mobile-catalog-menu-trigger, #js-mobile-menu-trigger");
  var mobileMenuClose = $(".js-mobile-menu-close");
  var mobileMenu = $(".mobile-catalog-menu, .mobile-menu");
  var mobileMenuNext = $(".js-mobile-menu-trigger");
  var mobileMenuPrev = $(".js-mobile-menu-back");

  function getRootLevelFromPage() {
    var firstElementWithDataLevel = $("[data-level]", mobileMenu).first();
    return Number(firstElementWithDataLevel.data("level")) - 1;
  }
  var rootLevel = getRootLevelFromPage();

  // Returns the current node if any
  function getSelectedNode() {
    var selectedNode = $("[data-selected]").slice(-1);

    if (selectedNode.length > 0) {
      return selectedNode;
    }

    return null;
  }

  // Set the current level from the current node, if any
  function getLevelFromPage() {
    var selectedNode = getSelectedNode();
    var level = rootLevel;

    if (selectedNode) {
      level = Number(selectedNode.data("level"));
    }

    return level;
  }
  var currentLevel = getLevelFromPage();

  function showSubmenu(submenu) {
    submenu
      .parent()
      .siblings()
      .hide();
    submenu.parent().addClass("visually-hidden");
    submenu.show();
    submenu.addClass("active");
  }

  function hideSubmenu(submenu) {
    submenu
      .parent()
      .prev()
      .show();
    submenu.parent().removeClass("visually-hidden");
    submenu.removeClass("active");
  }

  function updateUI() {
    if (currentLevel === rootLevel || !mobileMenu.hasClass("active")) {
      mobileMenuPrev.hide();
    } else {
      mobileMenuPrev.show();
    }
  }

  function menuOpen() {
    mobileMenu.addClass("active");
    main.addClass("mobile-menu-active");

    // If there is a selected node, open the menu starting from there
    var selectedNode = getSelectedNode();
    if (selectedNode) {
      var selectedSubmenu = selectedNode.parents(".sub-menu");
      if (selectedSubmenu.length > 0) {
        showSubmenu(selectedSubmenu);
        currentLevel = selectedSubmenu.first().data("level");
      } else {
        currentLevel = rootLevel;
      }
    }

    updateUI();
  }

  function menuClose() {
    mobileMenu.removeClass("active");
    main.removeClass("mobile-menu-active");
    updateUI();
  }

  function menuNext() {
    var submenu = $(this).next();
    showSubmenu(submenu);
    currentLevel++;
    updateUI();
  }

  function menuPrev() {
    var submenu = $('.sub-menu[data-level="' + currentLevel + '"]');
    hideSubmenu(submenu);
    currentLevel--;
    updateUI();
  }

  mobileMenuNext.on("click", menuNext);
  mobileMenuPrev.on("click", menuPrev);
  mobileMenuTrigger.on("click", menuOpen);
  mobileMenuClose.on("click", menuClose);
});
