"use strict";

$(document).ready(function() {
  var requestTypeField = $("#id_request_type");
  var uniqueCodeFieldContainer = $(".form-field--unique_code");

  uniqueCodeFieldContainer.hide();

  requestTypeField.on("change", function() {
    var valueSelected = this.value;

    if (valueSelected === "activate_ebook") {
      uniqueCodeFieldContainer.show();
    } else {
      uniqueCodeFieldContainer.hide();
    }
  });
});
